import React from 'react';
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { usePageSetup } from "@components/usePageSetup"
import { Layout } from "@components/Layout"
import { Seo } from "@components/Seo"
import { Hero } from "@components/Hero"
import { Video } from "@components/Secondary"
import { Arrow } from "@components/UI"
import { Lottie } from "@components/Lottie"
import { splitText } from "@utils"
import HolidayLightsA from "@svg/holiday/lights-a.svg"
import HolidayLightsB from "@svg/holiday/lights-b.svg"
import newHiresLottie from "@lottie/new_hires.json"

const HolidayTwentyTwentyTwo = ({ data }) => {
  const { givingBackImage, certifiedImage } = data
  const { themeColor } =usePageSetup({ themeColor: "bg-blue-light", displayGeneralForm: false })
  return (
    <Layout>
      <Seo title="Holiday 2022" description="" />

      <section className={themeColor}>
        <Hero className="pb-clamp-14-25 pt-clamp-16-26">
          <div className="w-full lg:mx-auto lg:w-8/12">
            <h2 className="font-bold text-left text-18">HAPPY HOLIDAYS</h2>
          </div>
          <div className="w-full mt-6">
            <div className="mx-auto lg:w-8/12">
              <h1
                className="font-normal font-lobster text-headline"
                data-aos="stagger"
                dangerouslySetInnerHTML={{
                  __html: splitText({
                    "text-white": "It was a very good year.",
                  }),
                }}
              />
              <div data-aos="fade-up" data-aos-delay="400">
                <p className="mt-9">A look back at some of the things that made 2022 special for JK… and warmest wishes for a wonderful new year ahead.</p>
              </div>
            </div>
          </div>
        </Hero>
      </section>
      <main className="overflow-hidden">

        <section className={`relative ${themeColor}`}>
          <div className="absolute inset-0 flex">
            <div className={`w-full bg-white-dark h-1/2 mt-auto`}></div>
          </div>
          <div className="container flex justify-center">
            <div className="w-full lg:w-10/12">
              <div className="grid grid-cols-10 lg:block">
                <div className="col-span-8 col-start-2 text-center sm:col-span-6 sm:col-start-3" data-aos="fade-up">
                  <p className="text-clamp-14-18">Click the play button for a special holiday greeting from JK (and some of our furry friends)!</p>
                </div>
              </div>
              <div className="shadow-md mt-7">
                <Video id="778922184" title="Holiday 2022" loop={1} />
              </div>
            </div>
          </div>
        </section>

        <Arrow colorTop="bg-transparent" colorBottom="bg-gray-darkest" fillColor="text-gray-darkest" />

        <div className="flex justify-center pt-clamp-24-48">
          <div className="overflow-hidden max-h-72 lg:max-h-full">
            <HolidayLightsA />
          </div>
        </div>

        <section className="relative lg:py-12">
          <div className="container flex justify-center">
            <div className="grid w-full lg:w-10/12 gap-y-24">

              <div className="grid-cols-10 gap-4 lg:grid">
                <div className="flex items-center col-span-5 col-start-2">
                  <div>
                    <h3 className="font-lobster text-clamp-40-50 text-blue-light">Giving back.</h3>
                    <p className="mt-clamp-5-10">At JK, helping our communities is part of our culture. We’re proud to say that we gave our time, talents, and donations to a variety of charitable causes in 2022—from our annual sock drive to harvesting fruits and veggies for food banks (and of course, helping animals find forever families!). <a href="/blog/celebrating-giving-tuesday" target="_blank" className="underline transition-all duration-300 text-blue hover:text-teal">Read our blog</a> to learn more about the groups we supported in 2022.</p>
                  </div>
                </div>
                <div className="grid-cols-8 col-span-4 mt-8 lg:grid lg:mt-0">
                  <div className="col-span-7 col-start-2">
                    <GatsbyImage
                      objectPosition="top"
                      objectFit="cover"
                      image={getImage(givingBackImage)}
                      alt="Giving back."
                    />
                  </div>
                </div>
              </div>

              <div className="flex flex-wrap-reverse grid-cols-10 gap-4 lg:grid">
                <div className="w-full grid-cols-8 col-span-4 mt-8 lg:grid lg:mt-0">
                  <div className="col-span-7">
                    <GatsbyImage
                      objectPosition="top"
                      objectFit="cover"
                      image={getImage(certifiedImage)}
                      alt="Getting certified."
                    />
                  </div>
                </div>
                <div className="flex items-center col-span-5">
                  <div className="text-left">
                    <h3 className="font-lobster text-clamp-40-50 text-blue-light">Getting certified.</h3>
                    <p className="mt-clamp-5-10">As a Women’s Business Enterprise (WBE) through the Women’s Business Enterprise National Council (WBENC), that is! As a women-owned agency with a female CEO and many outstanding women in leadership positions, we’re thrilled to have this status recognized by WBENC—and excited about the new chances ahead to support our clients’ supplier diversity goals. <a href="/blog/jk-design-recognized-as-a-wbenc-certified-womens-business-enterprise" target="_blank" className="underline transition-all duration-300 text-blue hover:text-teal">Read our blog</a> to learn more.</p>
                  </div>
                </div>
              </div>

              <div className="grid-cols-10 gap-4 lg:grid">
                <div className="flex items-center col-span-5 col-start-2">
                  <div>
                    <h3 className="font-lobster text-clamp-40-50 text-blue-light">Growing together.</h3>
                    <p className="mt-clamp-5-10">We were excited to welcome 10 new employees to the JK family in 2022! Their strong skills, diverse experiences, and passion for creating great work together is helping us grow our capabilities as an agency—and become an even better partner to our clients.</p>
                  </div>
                </div>
                <div className="grid-cols-8 col-span-4 mt-8 lg:grid lg:mt-0">
                  <div className="col-span-8">
                    <Lottie animation={newHiresLottie} className="mx-auto" svgParentClassName="w-full" />
                  </div>
                </div>
              </div>

            </div>
          </div>
        </section>

        <div className="flex justify-center">
          <div>
            <HolidayLightsB />
          </div>
        </div>

      </main>
    </Layout>
  )
}

export default HolidayTwentyTwentyTwo

export const HolidayTwentyTwentyTwoQuery = graphql`
  query holidayTwentyTwentyTwoQuery {
    imageA: file(relativePath: { eq: "holiday/image-a.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    givingBackImage: file(relativePath: { eq: "holiday/giving-back.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    certifiedImage: file(relativePath: { eq: "holiday/certified.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`
